

































































































































































import { Component, Vue } from 'vue-property-decorator';
import InvestmentStrategySchedulePaymentViewModel from
  '@/vue-app/view-models/allianz-dashboard/access-more-strategies/investment-strategy-schedule-payment-view-model';

@Component({ name: 'InvestmentStrategySchedulePayment' })
export default class InvestmentStrategySchedulePayment extends Vue {
  step_schedule_payment_date_model = Vue.observable(
    new InvestmentStrategySchedulePaymentViewModel(this),
  );

  created() {
    this.step_schedule_payment_date_model.initialize();
  }
}
