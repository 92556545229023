import i18n from '@/vue-app/plugins/i18n';
import TYPES from '@/types';
import Vue from 'vue';

// Application
import {
  GetAllianzAccountQuery,
} from '@/modules/my-investment/allianz/allianz-account/application/queries';
import AccessMoreStrategiesStateManagerService
  from '@/modules/my-investment/allianz/access-more-strategies/application/services/access-more-strategies-state-manager-service';

// Domain
import AccessMoreStrategiesState from '@/modules/my-investment/allianz/access-more-strategies/domain/state/access-more-strategies-state';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { Values } from '@/modules/shared/domain/i18n/types';
import Translator from '@/modules/shared/domain/i18n/translator';

export default class InvestmentStrategySchedulePaymentViewModel {
  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.ACCESS_MORE_STRATEGIES_STATE_MANAGER_SERVICE)
  private readonly manager_service!: AccessMoreStrategiesStateManagerService;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  private readonly view!: Vue;

  private readonly customer_id = sessionStorage.getItem('user_id');

  readonly i18n_namespace = 'components.flagship.flagship-link-goals.step-schedule-payment-date-wealth';

  AVAILABLE_DAYS = [...Array(28).keys()].map((i) => ({ item: `${i18n.t(`${this.i18n_namespace}.select_option`, { option: i + 1 })}`, value: i + 1 }));

  change_date = true;

  has_recurring_contributions = false;

  monthly_collection_day = 1;

  state: AccessMoreStrategiesState;

  public constructor(view: Vue) {
    this.view = view;
    this.state = this.manager_service.getAccessMoreStrategiesState();
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  loadRecurrentAssignedAmount = async () => {
    try {
      const {
        home_desire,
        monthly_collection_day,
      } = await this
        .get_allianz_account_query.execute({ customer_id: this.customer_id });
      this.state.recurring_contribution.monthly_collection_day = monthly_collection_day || 1;
      if (home_desire) {
        this.has_recurring_contributions = home_desire;
      }
    } catch (error) {
      this.message_notifier.showErrorNotification(this.translate('errors.get_recurrent_assigned_amount'));
    }
  }

  modifyDate = () => {
    if (!this.change_date) {
      this.state.recurring_contribution.monthly_collection_day = this.monthly_collection_day;
    }
  }

  initialize = async () => {
    this.state.is_loading = true;
    if (this.state.define_contribution_later) {
      this.endProcess();
    } else {
      await this.loadRecurrentAssignedAmount();
    }
    this.state.is_loading = false;
  }

  updatePaymentDate = async () => {
    this.endProcess();
  }

  endProcess = () => {
    this.view.$emit('endProcess');
  }

  goToPrevStep = () => {
    this.view.$emit('prevStep');
  }
}
